@charset "UTF-8";
.centering, .centering_fix, .page .page-thumb-wrapper .title-wrap, .single-thumb-wrapper .title-wrap {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX, .single-thumb-wrapper .title-wrap::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.page h4 {
  margin: 5rem 0 2rem;
  font-size: 1.5rem; }

.page .pink {
  color: #FF99CC; }

.page .page-thumb-wrapper {
  height: 27rem;
  position: relative;
  color: #EFEFEF; }
  .page .page-thumb-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }
  .page .page-thumb-wrapper .title-wrap {
    z-index: 2;
    font-size: 2rem; }
  .page .page-thumb-wrapper.archive {
    height: 15rem; }

.page .top-about-box {
  position: relative; }
  .page .top-about-box .item-r {
    position: absolute;
    top: 3rem;
    right: 0;
    letter-spacing: 6px;
    z-index: 3; }
  .page .top-about-box .img {
    box-shadow: 14px 13px 0px #EFEFEF; }
  .page .top-about-box .title span {
    display: inline-block;
    background-color: #fff;
    margin-left: 1rem;
    padding: 1rem;
    line-height: 1;
    white-space: nowrap; }

.page-wrap-en-title {
  position: absolute;
  left: -15%;
  top: -7rem; }
  .page-wrap-en-title .en-title {
    font-size: 15rem; }

.page-content p {
  margin: 2rem auto; }

.page-content .wp-block-image {
  margin: 2rem auto; }
  .page-content .wp-block-image:first-child {
    margin-top: 0; }

.page-content .wp-block-columns h3 {
  font-size: 1.5rem;
  background: linear-gradient(transparent 70%, #fff59a 70%);
  display: inline; }

.page-content .wp-block-columns .wp-block-image {
  margin: 2rem auto; }

.page-gallery div#n2-ss-3 .nextend-thumbnail-horizontal .nextend-thumbnail-scroller {
  background: none !important; }

.page-id-3 p {
  margin: 1em auto; }

.page-id-3 h2 {
  margin: 2em auto; }

.page-id-3 h3, .page-id-3 h4 {
  margin: 2em auto .5em;
  font-size: 1.2em; }

.page-id-3 ul {
  margin-left: 2em;
  list-style: circle; }

.page-id-3 .txt {
  margin-top: 0; }

.service-box-outline {
  margin-bottom: 22%; }
  .service-box-outline .img {
    box-shadow: -15px -12px 1px #FFE8F4; }
    .service-box-outline .img.yuuryou {
      box-shadow: -15px -12px 1px #DEFFF1; }
    .service-box-outline .img.houmon {
      box-shadow: -15px -12px 1px #FFF5D8; }

.service-box-sv ul li {
  margin-bottom: .25rem; }

.service-box-sv .img {
  border-radius: 2rem;
  margin-bottom: 2rem; }

.service-table-outline {
  position: absolute;
  width: 111%;
  right: 0;
  top: 3rem; }
  .service-table-outline .text-box {
    margin-left: 9rem;
    margin-bottom: 2rem; }

.day-section p {
  margin: 0; }

.day-section .sub-title {
  font-size: 3.25rem;
  color: #FF99CC;
  margin-bottom: 2rem; }

.day-section .img {
  background-size: cover;
  background-position: center; }

.day-section .title-wrap {
  margin-bottom: 2.5rem; }
  .day-section .title-wrap::after {
    content: "";
    background: url(../../images/icon/page-title.svg) no-repeat;
    width: 4rem;
    height: .8rem;
    background-size: contain;
    margin-top: .5rem; }

.day-section .item-r .content {
  margin-left: 2rem;
  padding: 2rem; }

.d-section2 {
  order: 2; }
  .d-section2 .item-r {
    order: 1; }
    .d-section2 .item-r .content {
      margin-left: 0;
      padding-left: 0; }
  .d-section2 .item-l {
    order: 2; }

.d-section3 {
  text-align: center; }
  .d-section3 .img {
    height: 27rem;
    margin: 2rem auto; }
  .d-section3 .title-wrap::after {
    display: inline-block; }
  .d-section3.dog .img {
    width: 27rem; }

.day-s-title {
  text-align: center;
  font-size: 2rem;
  color: #FFCF48; }

.d-section-title {
  font-size: 4.5rem;
  margin-bottom: 2rem;
  line-height: 1.2; }

.content404 p {
  margin: 2rem auto; }

.content404 .img {
  margin-bottom: 2rem; }

.archive-list .item {
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  position: relative; }
  .archive-list .item .thumb {
    padding-top: 60%;
    background-position: center;
    background-size: cover; }
  .archive-list .item .text-box {
    margin: 1rem auto;
    padding: 0 1rem; }
    .archive-list .item .text-box .admin {
      font-size: 14px;
      color: #778ba9;
      margin-bottom: .25rem; }
    .archive-list .item .text-box .title {
      font-size: 1.25rem;
      max-height: 4rem;
      margin-bottom: 1rem;
      overflow: hidden;
      line-height: 1.4; }

.mg-policy, .philosophy-list {
  text-align: center;
  font-family: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  font-size: 1.25rem; }
  .mg-policy .item, .philosophy-list .item {
    margin-bottom: 2rem; }
    .mg-policy .item:last-child, .philosophy-list .item:last-child {
      margin-bottom: 0; }

.philosophy-title {
  margin-bottom: 1rem; }

.single-content h2, .single-content h3, .single-content h4, .single-content p {
  margin-bottom: 2rem; }
  .single-content h2:last-child, .single-content h3:last-child, .single-content h4:last-child, .single-content p:last-child {
    margin-bottom: 0; }

.single-content h2 {
  font-size: 1.75rem;
  border-left: 6px solid #FFCF48;
  padding-left: 1rem; }

.single-content h3, .single-content h4 {
  font-size: 1.5rem; }

.single-content ul {
  list-style: disc; }

.single-content ol, .single-content ul {
  margin: 2rem 0 2rem 1.5rem; }
  .single-content ol li, .single-content ul li {
    margin-bottom: .5rem; }

.single-content figure {
  margin-bottom: 2rem; }

.single-content .wp-block-quote {
  border-left: 4px solid #cdd3dc;
  background: #f3f5f7;
  padding: 1rem; }
  .single-content .wp-block-quote p {
    margin-bottom: 1rem; }
  .single-content .wp-block-quote cite {
    text-align: right;
    display: block;
    color: #9e9e9e; }

.side-article-list {
  margin: 1rem auto; }
  .side-article-list .title {
    font-size: 1rem;
    padding: 0; }
  .side-article-list .article-box {
    padding-bottom: .5rem;
    border-bottom: 1px dotted #eee;
    margin-bottom: .5rem; }

.single-side-box {
  border: 1px solid #eee;
  padding: 1rem; }
  .single-side-box .btn a {
    display: block; }

.article-wrap {
  border: 1px solid #eee;
  padding: 3rem; }

.side-title {
  text-align: center;
  padding-bottom: .5rem;
  border-bottom: 1px solid #eee; }

.single-thumb-wrapper {
  height: 12rem;
  position: relative;
  color: #fff; }
  .single-thumb-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(46, 165, 224, 0.23);
    z-index: 1; }
  .single-thumb-wrapper .title-wrap {
    z-index: 2;
    font-size: 4rem; }
    .single-thumb-wrapper .title-wrap::after {
      content: "";
      background: url(../../images/icon/page-title.svg) no-repeat;
      width: 4rem;
      height: .8rem;
      background-size: contain;
      margin-top: .5rem;
      top: 82%; }

.archive .article-list {
  margin-bottom: 0; }
  .archive .article-list .news-title {
    font-weight: normal; }

.about-section {
  background: #fffceb;
  padding: 2rem 0; }

.op-table {
  font-size: 14px;
  border-collapse: collapse; }
  .op-table th, .op-table td {
    padding: 1rem;
    background: #fff;
    border: 1px solid #eee; }

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .page .page-thumb-wrapper {
    height: 30rem; }
  .page-wrap-en-title {
    position: absolute;
    left: -2%;
    top: -7rem; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .page .page-thumb-wrapper {
    height: 31rem; }
  .page .top-about-box .item-r {
    z-index: 3; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .page .page-thumb-wrapper {
    height: 25rem; }
  .service-box-outline {
    margin-bottom: 22%;
    height: 27rem; }
    .service-box-outline .text-box {
      margin-left: 15%;
      margin-bottom: 2rem; } }

@media screen and (max-width: 980px) {
  .page .page-thumb-wrapper {
    height: 9rem; }
    .page .page-thumb-wrapper .title-wrap {
      font-size: 1.5rem;
      line-height: 1.4;
      font-weight: bold;
      width: 100%;
      padding: 1rem; }
  .page-content .wp-block-image {
    margin: 2rem auto; }
  .single-content {
    padding: 8%; }
    .single-content h2 {
      font-size: 1.5rem;
      line-height: 1.4; }
  .single-header {
    padding: 8%; }
  .archive-list .item {
    margin-bottom: 1rem; }
  .page-wrap-en-title {
    position: absolute;
    left: 0;
    top: -2.5rem; }
    .page-wrap-en-title .en-title {
      font-size: 3rem;
      writing-mode: initial; }
  .page .top-about-box .item-r {
    top: initial;
    bottom: 0;
    right: 0; }
  .page .top-about-box .title span {
    background: none;
    margin-left: 0;
    padding: 0;
    color: #fff; }
  .mg-policy .item, .philosophy-list .item, .philosophy-list .item {
    margin-bottom: 2rem;
    font-size: 1rem; }
  .page .flex-20-75 {
    flex-wrap: wrap; }
    .page .flex-20-75 > .item-l {
      flex-basis: 100%;
      order: 2; }
    .page .flex-20-75 > .item-r {
      flex-basis: 100%;
      order: 1;
      margin-bottom: 2rem; }
  .article-wrap {
    border: none;
    padding: 0; }
  .single-header {
    padding: 0rem; }
  .single-content {
    padding: 0; }
  .service-box-outline {
    margin-bottom: 22%;
    display: block; }
    .service-box-outline .text-box {
      margin: 1rem auto; }
  .day-section {
    flex-wrap: wrap; }
    .day-section .img {
      height: 13rem;
      margin-bottom: 2rem; }
    .day-section .sub-title {
      font-size: 2rem; }
    .day-section .item-r {
      flex-basis: 100%; }
      .day-section .item-r .content {
        margin: 0;
        padding: 0; }
    .day-section .item-l {
      flex-basis: 100%; }
  .d-section2 .item-r {
    order: 2; }
  .d-section3 {
    text-align: center; }
    .d-section3 .img {
      height: 280px; }
    .d-section3.dog .img {
      width: 280px; }
  .d-section-title {
    font-size: 2.5rem; }
  .service-table-outline {
    position: static;
    width: 100%; } }
