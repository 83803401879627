@import '../_var';
@import '../_extends';
@import '../_mixins';

.page {
	h4 {
		margin: 5rem 0 2rem;
		font-size: 1.5rem;
	}
	.pink {
		color: $pink;
	}
	.page-thumb-wrapper {
		height: 27rem;
		position: relative;
		color: $gray;
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			// background-color: rgba(46, 165, 224, 0.23);
			z-index: 1;
		}
		.title-wrap {
			@extend .centering;
			z-index: 2;
			font-size: 2rem;
			// &::after {
			// 	content: "";
			// 	background: url(../../images/icon/page-title.svg) no-repeat;
			// 	width: 4rem;
			// 	height: .8rem;
			// 	background-size: contain;
			// 	margin-top: .5rem;
			// 	@extend .centeringX;
			// }
		}
		&.archive {
			height: 15rem;
		}
	}
	.top-about-box {
		position: relative;
		.item-r {
			position: absolute;
			top: 3rem;
			right: 0;
			letter-spacing: 6px;
			z-index: 3;
		}
		.img {
			box-shadow: 14px 13px 0px $gray;
		}
		.title {
			span {
				display: inline-block;
				background-color: #fff;
				margin-left: 1rem;
				padding: 1rem;
				line-height: 1;
				white-space: nowrap;
			}
		}
	}
}
.page-wrap-en-title {
	position: absolute;
	left: -15%;
	top: -7rem;
	.en-title {
		font-size: 15rem;
	}
}
.page-content {
	p {
		margin: 2rem auto;
	}
	.wp-block-image {
		margin: 2rem auto;
		&:first-child {
			margin-top: 0;
		}
	}
	.wp-block-columns {
		h3 {
			font-size: 1.5rem;
			background: linear-gradient(transparent 70%, #fff59a 70%);
			display: inline;
		}
		.wp-block-image {
			margin: 2rem auto;
		}
	}
}
.page-gallery {
	div#n2-ss-3 .nextend-thumbnail-horizontal .nextend-thumbnail-scroller {
		background: none !important;
	}
}

// privacy
.page-id-3 {
	p {
		margin: 1em auto;
	}
	h2 {
		margin: 2em auto;
	}
	h3,h4 {
		margin: 2em auto .5em;
		font-size: 1.2em;
	}
	ul {
		margin-left: 2em;
		list-style: circle;
	}
	.txt {
		margin-top: 0;
	}
}


// service
.service-box-outline {
	margin-bottom: 22%;
	.img {
		box-shadow: -15px -12px 1px #FFE8F4;
		&.yuuryou {
			box-shadow: -15px -12px 1px #DEFFF1;
		}
		&.houmon {
			box-shadow: -15px -12px 1px #FFF5D8;
		}
	}
}
.service-box-sv {
	ul {
		li {
			margin-bottom: .25rem;
		}
	}
	.item {
		
	}
	.img {
		border-radius: 2rem;
		margin-bottom: 2rem;
	}
}

// day
.service-table-outline {
	position: absolute;
	width: 111%;
	right: 0;
	top: 3rem;
	.text-box {
		margin-left: 9rem;
		margin-bottom: 2rem;
	}
}
.day-section {
	p {
		margin: 0;
	}
	.sub-title {
		font-size: 3.25rem;
		color: $pink;
		margin-bottom: 2rem;
	}
	.img {
		background-size: cover;
		background-position: center;
	}
	.title-wrap {
		margin-bottom: 2.5rem;
		&::after {
			content: "";
			background: url(../../images/icon/page-title.svg) no-repeat;
			width: 4rem;
			height: .8rem;
			background-size: contain;
			margin-top: .5rem;
		}
	}
	.item-r {
		.content {
			margin-left: 2rem;
			padding: 2rem;

		}
	}

}
.d-section2 {
	order: 2;
	.item-r {
		order: 1;
		.content {
			margin-left: 0;
			padding-left: 0;
		}
	}
	.item-l {
		order: 2;
	}
}
.d-section3 {
	text-align: center;
	.img {
		height: 27rem;
		margin: 2rem auto;
	}
	.title-wrap {
		&::after {
			display: inline-block;
		}
	}
	&.dog {
		.img {
			width: 27rem;
		}
	}
}
.day-s-title {
	text-align: center;
	font-size: 2rem;
	color: $yellow;
}
.d-section-title {
	font-size: 4.5rem;
	margin-bottom: 2rem;
	line-height: 1.2;
}

.content404 {
	p {
		margin: 2rem auto;
	}
	.img {
		margin-bottom: 2rem;
	}
}

// archive 
.archive-list {
	.item {
		border-radius: 12px;
		overflow: hidden;
		background-color: #fff;
		position: relative;
		.thumb {
			padding-top: 60%;
			background-position: center;
			background-size: cover;
		}
		.text-box {
			margin: 1rem auto;
			padding: 0 1rem;
			.admin {
				font-size: 14px;
				color: #778ba9;
				margin-bottom: .25rem;
			}
			.title {
				font-size: 1.25rem;
				max-height: 4rem;
				margin-bottom: 1rem;
				overflow: hidden;
				line-height: 1.4;
			}
		}
	}
}


.mg-policy {
	text-align: center;
	font-family: $mincho;
	font-size: 1.25rem;
	.item {
		margin-bottom: 2rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.philosophy-list {
	@extend .mg-policy;
}
.philosophy-title {
	margin-bottom: 1rem;
}



// single
.single-content {
	h2,h3,h4,p {
		margin-bottom: 2rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
	h2 {
		font-size: 1.75rem;
		border-left: 6px solid $yellow;
		padding-left: 1rem;
	}
	h3,h4 {
		font-size: 1.5rem;
	}
	ul {
		list-style: disc;
	}
	ol,ul {
		margin: 2rem 0 2rem 1.5rem;
		li {
			margin-bottom: .5rem;
		}
	}
	figure {
		margin-bottom: 2rem;
	}
	.wp-block-quote {
		border-left: 4px solid #cdd3dc;
		background: #f3f5f7;
		padding: 1rem;
		p {
			margin-bottom: 1rem;
		}
		cite {
			text-align: right;
			display: block;
			color: #9e9e9e;
		}
	}
}
.side-article-list {
	margin: 1rem auto;
	.title {
		font-size: 1rem;
		padding: 0;
	}
	.article-box {
		padding-bottom: .5rem;
		border-bottom: 1px dotted #eee;
		margin-bottom: .5rem;
	}
}
.single-side-box {
	border: 1px solid #eee;
	padding: 1rem;
	.btn {
		a {
			display: block;
		}
	}
}
.article-wrap {
	border: 1px solid #eee;
	padding: 3rem;
}
.side-title {
	text-align: center;
	padding-bottom: .5rem;
	border-bottom: 1px solid #eee;
}
.single-thumb-wrapper {
	height: 12rem;
	position: relative;
	color: #fff;
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(46, 165, 224, 0.23);
		z-index: 1;
	}
	.title-wrap {
		@extend .centering;
		z-index: 2;
		font-size: 4rem;
		&::after {
			content: "";
			background: url(../../images/icon/page-title.svg) no-repeat;
			width: 4rem;
			height: .8rem;
			background-size: contain;
			margin-top: .5rem;
			top: 82%;
			@extend .centeringX;
		}
	}
}
.archive {
	.article-list {
		margin-bottom: 0;
		.news-title {
			font-weight: normal;
		}
	}
}


// about
.about-section {
	background: #fffceb;
	padding: 2rem 0;
}


// operation
.op-table {
	font-size: 14px;
	border-collapse: collapse;
	th,td {
		padding: 1rem;
		background: #fff;
		border: 1px solid #eee;
	}
}


@include mq(laptop) {
	.page .page-thumb-wrapper {
		height: 30rem;
	}
	.page-wrap-en-title {
		position: absolute;
		left: -2%;
		top: -7rem;
	}
}

@include mq(slaptop) {
	.page .page-thumb-wrapper {
		height: 31rem;
	}
	.page {
		.top-about-box .item-r {
			z-index: 3;
		}
	}
}

@include mq(wtab) {
	.page .page-thumb-wrapper {
		height: 25rem;
	}
	.service-box-outline {
		margin-bottom: 22%;
		height: 27rem;
		.text-box {
			margin-left: 15%;
			margin-bottom: 2rem;
		}
	}
}

@include mq {
	.page .page-thumb-wrapper {
		height: 9rem;
		.title-wrap {
			font-size: 1.5rem;
			line-height: 1.4;
			font-weight: bold;
			width: 100%;
			padding: 1rem;
		}
	}
	.page-content {
		.wp-block-image {
			margin: 2rem auto;
		}
	}
	.single-content {
		padding: 8%;
		h2 {
			font-size: 1.5rem;
			line-height: 1.4;
		}
	}
	.single-header {
		padding: 8%;
	}
	.archive-list {
		.item {
			margin-bottom: 1rem;
		}
	}
	.page-wrap-en-title {
		position: absolute;
		left: 0;
		top: -2.5rem;
		.en-title {
			font-size: 3rem;
			writing-mode: initial;
		}
	}
	.page {
		.top-about-box {
			.item-r {
				top: initial;
				bottom: 0;
				right: 0;
			}
			.title span {
				background: none;
				margin-left: 0;
				padding: 0;
				color: #fff;
			}
		}
	}
	.mg-policy .item, .philosophy-list .item {
		margin-bottom: 2rem;
		font-size: 1rem;
	}
	.page {
		.flex-20-75 {
			flex-wrap: wrap;
			& > .item-l {
				flex-basis: 100%;
				order: 2;
			}
			& > .item-r {
				flex-basis: 100%;
				order: 1;
				margin-bottom: 2rem;
			}
		}
	}
	.article-wrap {
		border: none;
		padding: 0;
	}
	.single-header {
		padding: 0rem;
	}
	.single-content {
		padding: 0;
	}
	.service-box-outline {
		margin-bottom: 22%;
		display: block;
		.text-box {
			margin: 1rem auto;
		}
	}
	.service-table-outline {
	}

	.day-section {
		flex-wrap: wrap;
		.img {
			height: 13rem;
			margin-bottom: 2rem;
		}
		.sub-title {
			font-size: 2rem;
		}
		.item-r {
			flex-basis: 100%;
			.content {
				margin: 0;
				padding: 0;
			}
		}
		.item-l {
			flex-basis: 100%;
		}
	}
	.d-section2 .item-r {
		order: 2;
	}
	.d-section3 {
		text-align: center;
		.img {
			height: 280px;
		}
		.title-wrap {
			&::after {
			}
		}
		&.dog {
			.img {
				width:280px;
			}
		}
	}
	.d-section-title {
		font-size: 2.5rem;
	}
	.service-table-outline {
		position: static;
		width: 100%;
	}
}
