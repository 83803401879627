//color
$green: #4FC994;

$blue: #0090D5;

$red: #FF625A;

$orange: #F4513B;

$gray: #EFEFEF;
$gray-d: #799AA7;

$black: #464646;

$yellow: #FFCF48;

$purple: #9076FF;

$pink: #FF99CC;

$text: #456571;
$text-green: #08C171;

// //font
$basefont: futura-pt,ryo-gothic-plusn, "Yu Gothic", YuGothic,-apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",メイリオ, Meiryo ,Helvetica, sans-serif;
$mincho: a-otf-ryumin-pr6n, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
