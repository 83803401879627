//==========================================================
//	メディアクエリ
//==========================================================
@mixin mq($breakpoint: sp) {
	@if $breakpoint == sp {
		@media screen and (max-width: 980px) {
			@content;
		}
	} @else if $breakpoint == slaptop {
		@media screen and (min-width: 1200px) and (max-width: 1440px) {
			@content;
		}
	} @else if $breakpoint == ssp {
		@media screen and (max-width: 600px) {
			@content;
		}
	} @else if $breakpoint == laptop {
		@media screen and (min-width: 1441px) and (max-width: 1600px) {
			@content;
		}
	} @else if $breakpoint == max {
		@media screen and (min-width: 1441px) {
			@content;
		}
	} @else if $breakpoint == wtab {
		@media screen and (min-width: 1000px) and (max-width: 1199px) {
			@content;
		}
	}
}
